body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.table-section{
  border-left: 3px solid rgba(0, 0, 0, 1);
  width: 200px !important;
  /* border-top: 3px solid rgba(0, 0, 0, 1);; */
}

.table-cell{
  border-left: 1px solid rgba(224, 224, 224, 1);
  width: 200px !important;
}

.table-row{
  /* border-bottom: 3px solid rgba(0, 0, 0, 1); */
  /* border-top: 3px solid rgba(0, 0, 0, 1); */
}
.page {
  width: 21cm;
  min-height: 29.7cm;
  /* padding: 2cm; */
  padding-left: 1cm;
  padding-right: 1cm;
  margin: 1cm auto;
  border: 1px white solid;
  border-radius: 5px;
  background: rgb(240, 229, 229);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
/* .subpage {
  padding: 1cm;
  border: 1px black solid;
  height: 256mm;
  outline: 2cm #ffffff solid;
} */

/* @page {
  size: A4;
  margin: 0;
} */